<template>
  <!-- 头部区域 -->
  <div class="iot-person public-page-style">
    <div style="width:100%;height:100%">
      <div class="title">
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item label="状态">
          <el-select
          size="mini"
            v-model="form.sendStatus"
            @change="changeList"
            placeholder=""
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-input
           size="mini"
            v-model="form.projectName"
            @change="changeList"
            placeholder="根据项目名称搜索"
          ></el-input>
        </el-form-item>
        <el-form-item>
    <el-button type="primary"  size="mini" plain @click="changeList">查询</el-button>
  </el-form-item>
      </el-form>
      </div>
      <!-- 支付栏 -->
      <div class="payBtnBox" style="padding: 0 55px" v-if="$store.state.permissionArr.includes('ccid:pay')">
        <div style="width: 180px;"></div>
        <div class="payBtnTitle">请先勾选要充值的卡,再选择套餐! <span style="color:red">支付金额为年费金额，标注流量为每月上限，超额停机</span> </div>
        <div style="width: 180px;display: flex;">
            <el-button type="primary" size="mini" @click="payIccid()" plain
              >充值</el-button
            >
          <div class="payBtn" @click="payLogChange">充值记录</div>
          </div>
    </div>
   
    <!-- 表单区域 -->
    <div class="body">
      <el-table
      ref="multipleTable"
      :data="list"
      style="width: 100%"
       :header-cell-style="{ background: theme.TABLE_HEADER_COLOR }"
       @selection-change="handleSelectionChange"
      >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="projectName" label="项目名称" width="180"> </el-table-column>
      <el-table-column prop="projectGroupName" label="所属项目组" width="180"> </el-table-column> 
      <el-table-column prop="iccid" width="200" label="ICCID"> </el-table-column>
      <el-table-column prop="cardStatus" label="卡状态" align="center"> </el-table-column>
      <el-table-column prop="total" width="80" label="总流量"> </el-table-column>
      <el-table-column  prop="allowance" label="剩余流量"  width="80" > </el-table-column>
      <el-table-column  prop="validdate" label="到期时间" width="120"> </el-table-column>
      <el-table-column  width="150"  label="当前状态">
      <template slot-scope="scope">
     <div style="display: flex;justify-content: center;">
     <el-tag size="" style="float: right" v-if="scope.row.sendStatus==0"  type="success" >正常</el-tag >
     <el-tag size="" style="float: right" v-if="scope.row.sendStatus==1"  type="danger" >流量不足</el-tag >
            <el-tag
              size=""
              style="float: right"
              v-if="scope.row.sendStatus==2"
              type="danger"
              >即将到期</el-tag
            >
            <el-tag
              size=""
              style="float: right"
              v-if="scope.row.sendStatus==3"
              type="danger"
              >流量不足和即将到期</el-tag
            >
            <el-tag
              size=""
              style="float: right"
              v-if="(scope.row.sendStatus==4)"
              type="danger"
              >已过期</el-tag
            >
            </div>
            
      </template>
      </el-table-column>
      <el-table-column width="200" label="选择套餐"  v-if="$store.state.permissionArr.includes('ccid:pay')">
            <template slot-scope="scope">
              <div>
                <el-select
                  v-model="scope.row.packageId"
                  placeholder="请选择"
                  clearable
                >
                  <el-option
                    v-for="item in scope.row.GprsPackageList"
                    :key="item.PackageId"
                    :label="item.PackageTitle"
                    :value="item.PackageId"
                  >
                  </el-option>
                </el-select>
              </div>
            </template>
          </el-table-column>
      <el-table-column
       width="150"
        label="刷新流量">
          <template slot-scope="scope">
            <div>
              <el-button type="primary"  size="mini" plain @click="setList(scope.row)">刷新</el-button>
            </div>
      </template>
      </el-table-column>
    </el-table>
    </div>
    <div class="foot">
    <el-pagination
      style="float: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="form.current"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="form.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="form.total"
    ></el-pagination>
    </div>
    </div>
     <!-- 流量充值弹出层S -->
     <el-dialog
      title="流量充值"
      :visible.sync="payIccidVis"
      v-if="payIccidVis"
      width="22%"
      style="text-align: left; color: #409eff"
    >
    <el-row v-if="isShow">
      <div class="payBox">
        <div style="margin: 15px 0; color: #409eff; font-size: 20px">
          微信支付
        </div>
        <div id="qrCode" style="width: 200px; height: 200px"></div>
        <div style="margin: 15px 0; color: red; font-size: 18px">
          ￥{{ money }}元/年
        </div>
      </div>
    </el-row>
    <el-row v-else style="width:378px;height:280px"> 
        <el-result icon="success" title="充值成功" class="result"></el-result>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" type="primary" @click.stop="payIccidVis = false"
          >关 闭</el-button
        >
      </div>
    </el-dialog>
    <!-- 流量充值弹出层E -->
    
     <!-- 充值记录弹出层S -->
     <el-dialog
      title="充值记录"
      :visible.sync="payLogVis"
      v-if="payLogVis"
      width="60%"
      top="3%"
      style="text-align: left; color: #0eedff"
    >
      <div>
        <el-table
        :header-cell-style="{ background: theme.TABLE_HEADER_COLOR }"
          ref="multipleTable"
          :data="payLogList"
          style="width: 100%"
          height="600px"
        >
          <el-table-column prop="outTradeNo" label="订单号" align="center"> </el-table-column>
          <el-table-column prop="description" label="商品描述" align="center">
          </el-table-column>
          <el-table-column prop="moneyNum" label="金额" align="center"> </el-table-column>
          <el-table-column prop="createTime" label="创建时间" width="165" align="center">
          </el-table-column>
          <el-table-column width="150" label="订单状态" align="center">
            <template slot-scope="scope">
              <div style="display: flex;justify-content: center;">
                <el-tag
                  size=""
                  v-if="
                    scope.row.payState == 0 && getPayTime(scope.row.createTime)
                  "
                  type="info"
                  >未支付</el-tag
                >
                <el-tag
                  size=""
                  v-else-if="scope.row.payState == 1"
                  type="success"
                  >已支付</el-tag
                >
                <el-tag size="" v-else type="info">已过期</el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="300" align="center">
            <template slot-scope="scope">
              <div>
                <el-button type="primary" size="mini" plain @click="getPayTopLog(scope.row.payIccidId)"
                  >查看详情</el-button
                >
                <el-button type="primary" size="mini"
                v-if="
                    scope.row.payState == 0 && getPayTime(scope.row.createTime)
                  "
                  plain
                  @click="payLogMn(scope.row)"
                  >充值</el-button
                >
                <!-- <el-button type="danger" size="mini" plain @click="delOrder(scope.row.payIccidId)">删除</el-button> -->
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="foot">
          <el-pagination
            background
            @size-change="payLogSizeChange"
            @current-change="payLogCurrentChange"
            :current-page="payLog.current"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="payLog.size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="payLog.total"
          ></el-pagination>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button
          size="mini"
          type="primary"
          @click.stop="getPayLogPage(), (payLogVis = false)"
          >关 闭</el-button
        >
      </div>
    </el-dialog>
    <!-- 充值记录弹出层E -->
    
    <!-- 订单详情弹出层S -->
    <el-dialog
      top="3%"
      title="订单详情"
      :visible.sync="topLogVis"
      v-if="topLogVis"
      width="60%"
      style="text-align: left; color: #0eedff"
    >
      <div>
        <el-table
        :header-cell-style="{ background: theme.TABLE_HEADER_COLOR }"
          ref="multipleTable"
          :data="payTopLogList"
          style="width: 100%"
          height="650px"
        >
          <el-table-column prop="projectName" label="项目名称">
          </el-table-column>
          <el-table-column prop="projectGroupName" label="所属项目组">
          </el-table-column>
          <el-table-column prop="iccid" label="ICCID"> </el-table-column>
          <el-table-column prop="packageTitle" label="套餐名称">
          </el-table-column>
          <el-table-column prop="topMoneyNum" label="金额"> </el-table-column>
          <el-table-column width="150" label="状态">
            <template slot-scope="scope">
              <div style="display: flex">
                <el-tag size="" v-if="scope.row.topState == 0" type="info"
                  >未充值</el-tag
                >
                <el-tag
                  size=""
                  v-else-if="scope.row.topState == 1"
                  type="success"
                  >充值成功</el-tag
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" type="primary" @click.stop="topLogVis = false"
          >关 闭</el-button
        >
      </div>
    </el-dialog>
    <!-- 订单详情弹出层E -->
  </div>
</template>

<script>
let that;
import QRCode from "qrcodejs2";
import { TABLE_HEADER_COLOR } from "../util/theme";
export default {
  name: "Iccid",
  data() {
    return {
      isShow:true,
      timer:null,
      theme:{
        TABLE_HEADER_COLOR,
      },
      list: [],
      topLogVis: false,
      payTopLogList: [],
      payLogList: [],
      payLogVis: false,
      payLog: {
        size: 10,
        total: 0,
        current: 1,
        userId: "",
      },
      money: 0,
      payIccidVis: false,
      multipleSelection:[],
      form: {
        userId: -1,
        size: 20,
        total:0,
        current: 1,
        sendStatus: 10,
        projectName: "",
      },
      options: [
        {
          label: "全部",
          value: 10,
        },
        {
          label: "正常",
          value: 0,
        },
        {
          label: "流量不足",
          value: 1,
        },
        {
          label: "已过期",
          value: 5,
        },
        {
          label: "套餐到期",
          value: 2,
        },
        {
          label: "流量不足和套餐到期",
          value: 3,
        },
      ],
    };
  },
  methods: {
    //得到支付时间
    getPayTime(time) {
      let times = +new Date(time);
      let newTime = +new Date();
      if (newTime < times + 7200000) {
        return true;
      } else {
        return false;
      }
    },
      //支付按钮
    payIccid() {
      if (that.multipleSelection.length < 1) {
        that.$message({
          type: "warning",
          message: "请选择要充值的卡！",
        });
        return;
      }
      let obj = {
        description: "PC流量充值",
        total: 0,
        topType:1,
        iccidList: [],
      };
      let flag = false;
      let money = 0;
      for (let i = 0; i < that.multipleSelection.length; i++) {
        let item = that.multipleSelection[i];
        if (!item.packageId) {
          that.$message({
            type: "warning",
            message: item.projectName + "请选择要充值的套餐！",
          });
          flag = true;
          return;
        }
        let check = item.GprsPackageList.filter((element) => {
          return element.PackageId == item.packageId;
        });
        check = check[0];
        money += check.SellPrice;
        obj.iccidList.push({
          projectId: item.projectId,
          packageId: check.PackageId,
          topMoneyNum: check.SellPrice,
          dtuId: item.dtuId,
          iccid: item.iccid,
          packageTitle: check.PackageTitle,
        });
      }
      if (flag) {
        return;
      }
      obj.total = money;
      that.money = money;
      that.payIccidNativePay(obj);
    },
      // Native支付下单
    payIccidNativePay(form) {
      this.isShow=true
      that.$api.iccid.payIccidNativePay(form).then((res) => {
        if (res.code == 200) {
          that.createQrCode(res.data.codeUrl);
          that.getPayStatus(res.data)
        }
      });
    },
    getPayStatus(data) {
      that.timer = setInterval(async () => {
         await   this.$api.iccid.payStatus(data.payIccidId).then((res) => {
              if (res.data.payState == 1) {
             that.isShow=false
             this.$message.success('充值成功')
             window.clearInterval(that.timer)
             that.timer = null
                setTimeout(() => {
                  that.payIccidVis = false
                  if (data.createTime) {
                    that.getPayLogPage();
                }
             },2000)
              }
            })
            if (!this.payIccidVis) {
            window.clearInterval(that.timer)
            that.timer = null
             return
            } 
         }, 2000)
     
    },
    // 创建二维码
    createQrCode(src) {
      that.payIccidVis = true;
      //创建二维码
      setTimeout(() => {
        new QRCode("qrCode", {
          width: 200,
          height: 200,
          colorDark: "#000000", //二维码颜色
          colorLight: "#ffffff", //二维码背景色
          correctLevel: QRCode.CorrectLevel.H, //容错率，L/M/H
          text: src,
        });
      }, 100);
    },
       //获取选择的流量卡
    handleSelectionChange(val) {
      that.multipleSelection = val;
      that.multipleSelection.forEach((element) => {
         if (element.GprsPackageList.length < 1 && element.getPackage) {
          that.packageList(element);
         }
      });
    },
    //获取套餐列表
    packageList(row) {
      that.$api.iccid.packageList(row).then((response) => {
        if (response.code == 200) {
          row.getPackage = false;
          if (!response.data.dtuIccid) {
            that.$message({
              type: "error",
              message: row.projectName + "此卡无法充值！",
            });
          }
          if (response.data.packList.length < 1) {
            that.$message({
              type: "error",
              message: row.projectName + "没有套餐！",
            });
          }
          row.GprsPackageList = response.data.packList;
        }
      });
    },
    setList(row){
      that.$api.iccid.checklccid(row.iccid).then(res=>{
        if (res.code==200) {
          that.getList();
          
        }else{
          that.$message.warning('res.msg')
        }
      });
    },
     //分页方法
     handleSizeChange(val) {
        that.form.size=val;
        that.getList();
      },
      handleCurrentChange(val) {
        that.form.current=val;
        that.getList();
      },
    changeList() {
      that.form.current = 1;
      that.getList();
    },
    getList() {
      that.$api.iccid.getIccidList(that.form).then((response) => {
        if (response.code == 200) {
          response.data.records.forEach((item) => {
            item["GprsPackageList"] = [];
            item["packageId"] = "";
            item["getPackage"] = true;
          });
          that.list = response.data.records;
          that.form.total=response.data.total
        } else {
          that.$message({
            type: "warning",
            message: response.msg,
          });
        }
      });
    },
  //分页方法
  payLogSizeChange(val) {
      that.payLog.size = val;
      that.getPayLogPage();
    },
    payLogCurrentChange(val) {
      that.payLog.current = val;
      that.getPayLogPage();
    },
    payLogChange() {
      that.getPayLogPage();
      that.payLogVis = true;
    },
    //获取用户移动卡充值订单
    getPayLogPage() {
      that.$api.iccid.getPayLogPage(that.payLog).then((response) => {
        console.log(response);
        if (response.code == 200) {
          that.payLogList = response.data.records;
          that.payLog.total = response.data.total;
        } else {
          that.$message.warning(response.msg)
        }
      });
    },
    //获取用户移动卡充值订单详情
    getPayTopLog(id) {
      that.$api.iccid.getPayTopLog(id).then((res) => {
        if (res.code == 200) {
          that.payTopLogList = res.data;
          that.topLogVis = true;
        }
      });
    },
    payLogMn(row) {
      that.isShow=true
      that.money = row.moneyNum;
      that.createQrCode(row.codeUrl);
      that.getPayStatus(row)
    },
    //删除订单
 async delOrder(id) {
   try {
   await  this.$confirm('确认删除？','提示',{
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning' })
     await this.$api.iccid.delOrder(id)
     this.$message.success('删除成功')
    this.getPayLogPage()
   } catch (e) {
    this.$message.info('取消删除')
   }
},
  },
  beforeMount() {
    that = this;
  },
  mounted() {
    that.payLog.userId=that.$store.state.userId
    that.form.userId=that.$store.state.userId;
    that.getList();
  },
};
</script>


<style scoped>
.title{
  width: 100%;
  height: 62px;
  box-sizing: border-box;
  padding: 10px 10px;
}
.body{
  height: 80%;
  overflow-y: auto;
}
.foot{
  height: 10%;
}
.payBtn {
  display: inline-block;
  height: 100%;
  line-height: 28px;
  color: #4e73df;
  text-decoration: underline;
  cursor: pointer;
  margin-left: 20px;
}
.foot {
  width: 100%;
  height: 10%;
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
}
.payBtnBox {
  height: 28px;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin: 8px 0;
}
.payBtnTitle {
  width: 80%;
  color: #4e73df;
  line-height: 28px;
  font-size: 16px;
  padding-left: 400px;
}
.payBox {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>
